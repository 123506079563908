import React, { useEffect, useState, useCallback } from 'react';
import { Spin } from 'antd';
import { HttpService } from '../../../../app/services/http.services';

type Props = {
	className: string;
};

const CustomTablesWidget5: React.FC<Props> = ({ className }) => {
	const [topThreeDevice, setTopThreeDevice] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const loadTopThreeDeviceData = useCallback(async () => {
		setLoading(true);
		setError(null);

		try {
			const response = await HttpService.get('auth/dashboard/devices/top/temperature');
			setTopThreeDevice(response.data.results);
		} catch (error) {
			console.error('Error fetching top devices data:', error);
			setError('Failed to load data. Please try again later.');
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		loadTopThreeDeviceData();
	}, [loadTopThreeDeviceData]);

	const renderDeviceList = () => {
		if (loading) {
			return <Spin size="small" />;
		}

		if (error) {
			return <div className="text-white">{error}</div>;
		}

		if (topThreeDevice.length === 0) {
			return <div className="text-white">No data available.</div>;
		}

		return topThreeDevice.map((item: any) => (
			<div
				key={item.equipment_id}
				className="d-flex justify-content-between fw-bold fs-6 text-white opacity-100 w-100 mt-auto mb-2"
			>
				<span>{item.device_name}</span>
				<span>
					{item.parameters[0].value} {item.parameters[0].unit}
				</span>
			</div>
		));
	};

	return (
		<div className={`card ${className}`} style={{ overflowY: 'auto', backgroundColor: '#2f8bf1b5' }}>
			<div className="card-header border-0 pt-5">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold fs-2 mb-1 text-white">
						Equipment with highest parameter (24 hour)
					</span>
				</h3>
			</div>

			<div className="card-body d-flex align-items-end pt-0">
				<div className="d-flex align-items-center flex-column mt-3 w-100">
					{renderDeviceList()}
				</div>
			</div>
		</div>
	);
};

export { CustomTablesWidget5 };