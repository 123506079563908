import { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  data?: any;
};

const Card2: FC<Props> = ({ data = [] }) => {
  var status = "light";

  if (data.latest_stream_data_status === "Inactive") {
    status = "secondary";
  } else if (data.latest_stream_data_status === "Online") {
    status = "success";
  }
  if (data.latest_stream_data_status === "Offline") {
    status = "danger";
  }

  return (
    <div className="card-xl-stretch card">
      <div className="card-header flex-nowrap border-0 pt-9">
        <div className="card-title align-items-start flex-column m-0">
          <Link to="/#" className="fs-3 fw-bolder text-dark">
            {data?.name}
          </Link>
          <div className="fw-semibold text-muted">
            {data.latest_stream_data ? data.latest_stream_data.updated_at : ""}
          </div>
        </div>
        <div className="card-toolbar m-0">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={`Status:  ${data.latest_stream_data_status}`}
            className={`bg-${status} rounded-circle border border-4 border-white h-20px w-20px`}
          ></span>
        </div>
      </div>
      <div className="card-body p-9">
        <div className="d-flex flex-wrap mb-0">
          {data.latest_stream_data?.details?.map((item: any, id: number) => {
            let parameterColor = "text-gray-800"; // Default color
            const value = parseFloat(item.datastream_value_masking);
            const thresholdLow = item.threshold_low;
            const thresholdHigh = item.threshold_high;

            // Check if thresholds are null or undefined
            if (thresholdLow == null || thresholdHigh == null) {
              parameterColor = "text-gray-800"; // Default color
            } else if (!isNaN(value)) {
              if (value < thresholdLow) {
                parameterColor = "text-warning"; // Below low threshold
              } else if (value > thresholdHigh) {
                parameterColor = "text-danger"; // Above high threshold
              } else {
                parameterColor = "text-gray-800"; // In range
              }
            }

            return (
              <div
                key={id}
                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"
              >
                {/* Tooltip on Hover */}
                <div
                  className={`fs-6 fw-bolder ${parameterColor}`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`Threshold: Low ${thresholdLow ?? "N/A"}, High ${thresholdHigh ?? "N/A"}`}
                >
                  {item.parameter_name}
                </div>
                {item.datastream === "lat_lng" ? (
                  <div className="fw-bold text-gray-400">
                    <a
                      href={`https://maps.google.com/?q=${item.datastream_value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Last Location
                    </a>
                  </div>
                ) : (
                  <div className="fw-bold text-gray-400">
                    {item.datastream_value_masking
                      ? item.datastream_value_masking +
                        " " +
                        item.unit_short_name
                      : ""}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Card2 };
