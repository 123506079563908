export const LeftSidebarMenu = [
    {
        type: "group",
        title: "Dashboard",
        hidden: true,
        children: [
            {
                type: "item",
                title: "Dashboard",
                permission: "auth:example:menuAccess",
                link: { to: "/admin/dashboard" },
                icon: "element-7",
                subParent: false,
                subChildren: []
            }
        ],
    },

    {
        type: "group",
        title: "Settings",
        hidden: false,
        children: [
            // COMPANY
            {
                type: "item",
                title: "Company",
                permission: "auth:organization:menuAccess",
                link: { to: "/" },
                icon: "abstract-43",
                subParent: true,
                subChildren: [
                    {
                        type: "item",
                        title: "Organization",
                        permission: "auth:organization:menuAccess",
                        link: { to: "/admin/setting/company/organization", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Organogram",
                        permission: "auth:organogram:menuAccess",
                        link: { to: "/admin/setting/company/organogram", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Users",
                        permission: "auth:user:menuAccess",
                        link: { to: "/admin/setting/company/users", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                ],
            },
            // PROFILE
            // {
            //     type: "item",
            //     title: "Profile",
            //     permission: "auth:example:menuAccess",
            //     link: { to: "/" },
            //     icon: "message-text-2",
            //     subParent: true,
            //     subChildren: [
            //         {
            //             type: "item",
            //             title: "My Profile",
            //             permission: "auth:example:menuAccess",
            //             link: { to: "/admin/setting/profile/own-profile", exactMatch: true, externalUrl: false, openInNewTab: false },
            //         },
            //         {
            //             type: "item",
            //             title: "Change Password",
            //             permission: "auth:oauth:changePassword",
            //             link: { to: "/admin/setting/profile/change-password", exactMatch: true, externalUrl: false, openInNewTab: false },
            //         },
            //     ],
            // },
            // ROLE PERMISSIONS
            {
                type: "item",
                title: "Permissions",
                permission: "auth:resource:menuAccess",
                link: { to: "/" },
                icon: "message-text-2",
                subParent: true,
                subChildren: [
                    {
                        type: "item",
                        title: "Resource",
                        permission: "auth:resource:menuAccess",
                        link: { to: "/admin/setting/permission/resource", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Scope",
                        permission: "auth:scope:menuAccess",
                        link: { to: "/admin/setting/permission/scope", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Role",
                        permission: "auth:role:menuAccess",
                        link: { to: "/admin/setting/permission/role", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Group",
                        permission: "auth:group:menuAccess",
                        link: { to: "/admin/setting/permission/group", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Permissions",
                        permission: "auth:permission:rolePermission",
                        link: { to: "/admin/setting/permission/permission-role", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                ],
            },

            {
                type: "item",
                title: "Icon",
                permission: "auth:unit:menuAccess",
                link: { to: "/admin/setting/icon", exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: "abstract-28",
                subParent: false,
                subChildren: []
            },

            {
                type: "item",
                title: "Unit",
                permission: "auth:unit:menuAccess",
                link: { to: "/admin/setting/unit", exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: "abstract-19",
                subParent: false,
                subChildren: []
            },

            {
                type: "item",
                title: "Parameters",
                permission: "auth:parameter:menuAccess",
                link: { to: "/admin/setting/device-sensor", exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: "abstract-27",
                subParent: false,
                subChildren: []
            },

            {
                type: "item",
                title: "Gateway Type",
                permission: "auth:gatewayType:menuAccess",
                link: { to: "/admin/setting/gateway-type", exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: "abstract-26",
                subParent: false,
                subChildren: []
            },

            {
                type: "item",
                title: "Gateway Enrollment",
                permission: "auth:gatewayEnrollment:menuAccess",
                link: { to: "/admin/ems/gateway-enrollment", exactMatch: true, externalUrl: false, openInNewTab: false },
                icon: "abstract-22",
                subParent: false,
                subChildren: []
            },
        ],
    },

    {
        type: "group",
        title: "EMS Menu",
        hidden: false,
        children: [
            
            // Device Category
            {
                type: "item",
                title: "Equipment",
                permission: "auth:example:menuAccess",
                link: { to: "/" },
                icon: "abstract-30",
                subParent: true,
                subChildren: [
                    {
                        type: "item",
                        title: "Category",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/ems/device-category", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Sub Category",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/ems/device-sub-category", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Equipment",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/ems/device", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Equipment Setting",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/ems/setting", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Equipment Map Setting",
                        permission: "auth:equipmentMap:menuAccess",
                        link: { to: "/admin/ems/setting/map", exactMatch: true, externalUrl: false, openInNewTab: false },
                    }
                ],
            },

            // REPORTS
            {
                type: "item",
                title: "Reports",
                permission: "auth:example:menuAccess",
                link: { to: "/" },
                icon: "files-tablet",
                subParent: true,
                subChildren: [
                    // {
                    //     type: "item",
                    //     title: "Report",
                    //     permission: "auth:example:menuAccess",
                    //     link: { to: "/admin/report/report-one", exactMatch: true, externalUrl: false, openInNewTab: false },
                    // },
                    {
                        type: "item",
                        title: "Command Log",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/report/command", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Details Report",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/report/report", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Events",
                        permission: "auth:example:menuAccess",
                        link: { to: "/admin/report/notification", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Min Max Avg",
                        permission: "auth:analytics:menuAccess",
                        link: { to: "/admin/report/analytics", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Equipment latest data",
                        permission: "auth:latestData:menuAccess",
                        link: { to: "/admin/report/equipment/latest/data", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    {
                        type: "item",
                        title: "Cement Mixture Map",
                        permission: "auth:equipmentMap:menuAccess",
                        link: { to: "/admin/report/cement/mixture/map", exactMatch: true, externalUrl: false, openInNewTab: false },
                    },
                    // {
                    //     type: "item",
                    //     title: "Map Geo Fencing",
                    //     permission: "auth:example:menuAccess",
                    //     link: { to: "/admin/report/map/geofencing", exactMatch: true, externalUrl: false, openInNewTab: false },
                    // },
                ],
            },
        ],
    },
];
